import { GET_CLEARANCE_DATA } from 'mocks/queries';
import { makeVar } from '@apollo/client';

const DEFAULT_CLEARANCE_STOCK_VAR = makeVar({});

class ClearanceStock {
  constructor() {
    this.zipCode = null;
    this.cacheLoading = false;
  }

  getClearanceStock = async (client, zipCode, fromCache, warehouseId) => {
    if (
      this.cacheLoading ||
      (this.zipCode === zipCode && this.warehouseId === warehouseId)
    ) {
      return;
    }

    const clearanceStockReactiveVar =
      client.cache.jbReactiveVars?.clearanceStockVar;

    const clearanceStockVar =
      clearanceStockReactiveVar || DEFAULT_CLEARANCE_STOCK_VAR;

    if (!this.cacheLoading) {
      this.cacheLoading = true;

      if (typeof clearanceStockVar === 'function') {
        clearanceStockVar({ loading: true });
      }
    }

    this.zipCode = (zipCode || '').toString();
    this.warehouseId = warehouseId;

    const clearanceRes = await client.query({
      query: GET_CLEARANCE_DATA,
      variables: {
        zipCode: this.zipCode,
        fromCache,
        warehouseId,
      },
      fetchPolicy: 'no-cache',
    });

    const dataFromResponse = clearanceRes?.data?.clearanceData?.data || {};

    if (typeof clearanceStockVar === 'function') {
      clearanceStockVar({
        ...dataFromResponse,
        zipCode: this.zipCode,
        loading: false,
      });
    }

    this.cacheLoading = false;
  };

  getClearanceStockLoadingStatus() {
    return this.cacheLoading;
  }
}

export default new ClearanceStock();
