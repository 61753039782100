import { isEmpty } from 'lodash';

import usePDPOptions from 'components/consumer/PDP/state/context/usePDPOptions';
import usePdpSelectedOptionsVariantSku from 'components/consumer/PDP/state/pdpSelectedOptionsVariantSku/usePdpSelectedOptionsVariantSku';
import usePDPBasicDetails from 'components/consumer/PDP/state/context/usePDPBasicDetails';
import useAllProductClearanceStock from '../useAllProductClearanceStock';

const usePDPClearanceData = (hookOptions = {}) => {
  const { skip = false, defaultValue = {} } = hookOptions;

  const {
    loading: clearanceLoading,
    data: clearanceData,
  } = useAllProductClearanceStock(skip);
  const {
    loading: basicLoading,
    error: basicError,
    data: basicData,
  } = usePDPBasicDetails();

  const { id: productId } = basicData || {};
  const { loading: optionsLoading, error: optionsError } = usePDPOptions();
  const selectedOptionsVariantSku = usePdpSelectedOptionsVariantSku();

  const isClearanceDataAvailable = !isEmpty(clearanceData);

  const clearanceStockPerSKU = isClearanceDataAvailable
    ? clearanceData[productId]?.[selectedOptionsVariantSku]
    : {};
  const isClearance = !isEmpty(clearanceStockPerSKU);
  const stockCost = isClearance
    ? parseFloat(clearanceStockPerSKU.stock_cost)
    : 0;

  const optionsClearanceData = isClearanceDataAvailable
    ? clearanceData[productId]
    : {};

  if (skip) {
    return defaultValue;
  }

  return {
    loading: clearanceLoading || basicLoading || optionsLoading,
    error: optionsError || basicError,
    data: {
      isClearance,
      stockCost,
      clearanceStockPerSKU,
      optionsClearanceData,
    },
  };
};

export default usePDPClearanceData;
