import { useCallback, useEffect, useMemo } from 'react';
import { useApolloClient, useReactiveVar, makeVar } from '@apollo/client';
import { isEmpty } from 'lodash';

import isClient from 'commons/isClient';
import useZipCode from 'global-state/zipCode/useZipCode';
import useWarehouseIdForUser from 'global-state/warehouseIdForUser/useWarehouseIdForUser';
import ClearanceStock from './ClearanceStock';

const DEFAULT_CLEARANCE_STOCK_VAR = makeVar({});

/* Gives all clearance stock (multiple products) for the user's zipcode
 * skipQuery - Allows skipping clearance stock fetch when used conditionally
 */
const useAllProductClearanceStock = (skipQuery = false) => {
  const apolloClient = useApolloClient();
  const zipCode = useZipCode();
  const warehouseId = useWarehouseIdForUser();

  const clearanceStockVar =
    apolloClient.cache.jbReactiveVars?.clearanceStockVar;

  const clearanceStockInCache = useReactiveVar(
    !!clearanceStockVar && isClient()
      ? clearanceStockVar
      : DEFAULT_CLEARANCE_STOCK_VAR
  );

  const fetchClearanceStock = useCallback(async () => {
    await ClearanceStock.getClearanceStock(
      apolloClient,
      zipCode,
      true,
      warehouseId
    );
  }, [apolloClient, zipCode, warehouseId]);

  useEffect(() => {
    if (skipQuery) {
      return;
    }
    fetchClearanceStock();
  }, [fetchClearanceStock, skipQuery]);

  useEffect(() => {
    if (!zipCode || skipQuery) {
      return;
    }
    fetchClearanceStock();
  }, [fetchClearanceStock, skipQuery, zipCode]);

  const clearanceStock = useMemo(() => {
    if (!isClient() || isEmpty(clearanceStockInCache)) {
      return {};
    }
    const keysToIgnore = ['loading', 'zipCode'];
    const finalClearanceStock = {};
    Object.keys(clearanceStockInCache || {}).forEach(clearanceKey => {
      if (!keysToIgnore.includes(clearanceKey)) {
        finalClearanceStock[clearanceKey] = {
          ...clearanceStockInCache[clearanceKey],
        };
      }
    });
    return finalClearanceStock;
  }, [clearanceStockInCache]);

  return {
    error: null,
    loading: !!clearanceStockInCache?.loading,
    data: clearanceStock,
  };
};

export default useAllProductClearanceStock;
